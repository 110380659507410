.text-error-center {
    position: absolute;
    top: 35%;
    right: 0;
    left: 0;
}

.titulo-error {
    color: #37B8CB;
    font-size: 88px;
    font-weight: bold;
}

.subtitle-error {
    font-size: 40px;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1280px) and (orientation:portrait) {
    .titulo-error {
        font-size: 133px;
    }
    .subtitle-error {
        font-size: 70px;
        padding-left: 10%;
        padding-right: 10%;
    }
}