.bg-detalle {
  background: #F3F3F3;
}

.bg-principal {
  background: #37B8CB;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* PROGRESS BAR VALIDACIÓN */
.progress_bar {
  height: 8px;
  background-color: #37B8CB;
  width: 100%;
  overflow: hidden;
}

.progress_bar_value {
  height: 8px;
  width: 100%;
  background-color: #2c90a0;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 100%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
