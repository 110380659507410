.cont{
      background: #37B8CB;
      width: 100%;
      height: 280px;
  }

  .lineh {
      line-height: 1;
      white-space: pre-wrap;
  }
  
  .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      z-index: 1031;
      padding: 5% 10% 5% 1%;
      position: absolute;
      top: 0;
      left: 0;
      /*background: rgb(0, 0, 0);*/
      /*opacity: .5;*/
  }

  .text-color-principal {
      color: #37B8CB;
  }
  
  
  .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 40%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 3% 30% 10% 30%;
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .icono_share_pago{
      margin-left: 105%;
      color: #fff;
      cursor: pointer;
  }
  .style_share{
      margin-top:-4%;
  }
  .icono_success_pago{
      width: 70px;
      height: 70px;
  }
  .icono_imprimir_pago{
      width: 30px;
      height: 30px;
      margin-top: -30%;
      margin-left: 94%;
  }
  
  .texto_exitoso{
      font-size: 26px;
      text-align: center;
      /* color: #37B8CB; */
      color: #37313F;
      font-weight: bold;
      margin-top: 5%;
  }
  /* textos */
  .style_hora{
      color: #37B8CB;
      font-weight: bold;
      margin-right: 12%;
  }
  .style_fecha{
      color: #37B8CB;
      font-weight: bold;
      margin-left: 25%;
  }
  .style_precio{
      font-size: 34px;
      font-weight: bold;
      color: #37B8CB;
      text-align: center;
      margin-top: 5%;
  }
  .style_nombre{
      font-size: 21px;
      color: #37B8CB;
      text-align: center;
  }
  .style_tarjeta{
      font-size: 13px;
      text-align: center;
  }
  .detalles_pago{
      margin-top: 7%;
  }
  .texto_descripcion{
      color: #37313F;
      /*font-weight: bold;*/
      margin-left: 25%;
      font-size: 13px;
  }
  .texto_enviadoA{
      color: #37313F;
      margin-left: 25%;
      font-size: 13px;
  }
  
  .texto_comercio{
      color: #37313F;
      margin-left: 24.5%;
      font-size: 13px;
  }
  .texto_desc{
      color: #37313F;
      margin-left: 28%;
      font-size: 13px;
  }
  .text_ref{
      color: #37313F;
      margin-left: 30%;
      font-size: 13px;
  }
  /*botones */
  .btn_guardar_ticket, .btn_guardar_ticket:hover{
      margin-right: 10%;
      background: #37B8CB;
      color: #fff;
  }
  
  .btn-primarye:hover {
      color: #000;
      background-color: #fff;
      border-color: #37313F;
  }
  
  .btn-secondarye:hover {
      color: white;
      background-color: #6c757d;
      border-color: #6c757d;
  }
  
  .btn-secondarye {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      
  }
  .botones_style{
      margin-top: 6%;
      text-align: center;
  }

  @media print {
    .noPrint{
      display:none;
    }
  }
  
  /*vertical */
  @media (orientation: portrait) and (min-device-width: 0px) and (max-device-width: 280px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 90%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 20% 5% 10% 5%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      z-index: 1031;
      padding: 10% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: -8%;
      margin-bottom: -10%;
      }
      .icono_success_pago {
      width: 70px;
      height: 70px;
      }
      .texto_exitoso {
      font-size: 25px;
      text-align: center;
      margin-top: 10%;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 10%;
      font-size: 13px;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: -1%;
       font-size: 13px; 
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 5%;
      font-size: 13px;
      }
      .detalles_pago {
      margin-top: 10%;
      margin-bottom: 15%;
      }
  }
  
  @media (orientation: portrait) and (min-device-width: 281px) and (max-device-width: 414px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 90%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 20% 5% 10% 5%;
      position: absolute;
      top: 20px;
      left: 0;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      z-index: 1031;
      padding: 6% 20% 6% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: -1%;
      margin-bottom: -10%;
      }
      .icono_success_pago {
      width: 70px;
      height: 70px;
      }
      .texto_exitoso {
      font-size: 25px;
      text-align: center;
      margin-top: 10%;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 10%;
      font-size: 11px;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      /*margin-right: -1%;*/
       font-size: 11px; 
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 15%;
      font-size: 13px;
      }
      .detalles_pago {
      margin-top: 10%;
      margin-bottom: 15%;
      }
  }
  
  @media (orientation: portrait) and (min-device-width: 415px) and (max-device-width: 540px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 90%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 5% 10% 5%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      z-index: 1031;
      padding: 1% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: 3%;
      margin-bottom: -6%;
      }
      .icono_share_pago {
      /* margin-top: -3%; */
      margin-left: 110%;
      color: #fff;
      cursor: pointer;
      }
      .style_fecha {
        margin-left: 17%;
    }
  }
  
  @media (orientation: portrait) and (min-device-width: 541px) and (max-device-width: 768px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 60%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 20% 10% 20%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      /*z-index: 1031;*/
      padding: 1% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: 5%;
      margin-bottom: -4%;
      }
      .icono_share_pago {
      /* margin-top: -3%; */
      margin-left: 115%;
      color: #fff;
      cursor: pointer;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 24%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 15%;
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 25%;
      font-size: 15px;
      }
      .texto_enviadoA{
      
      font-size: 15px;
      }
  
      .texto_comercio{
          
          font-size: 15px;
      }
      .texto_desc{
          
          font-size: 15px;
      }
      .text_ref{
          
          font-size: 15px;
      }
  
  }
  
  @media (orientation: portrait) and (min-device-width: 769px) and (max-device-width: 820px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 60%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 20% 10% 20%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .botones_style {
      margin-top: 6%;
      /* margin-left: 3%; */
      text-align: center;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      /*z-index: 1031;*/
      padding: 1% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: 5%;
      margin-bottom: -4%;
      }
      .icono_share_pago {
      /* margin-top: -3%; */
      margin-left: 115%;
      color: #fff;
      cursor: pointer;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 30%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 15%;
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 25%;
      font-size: 15px;
      }
      .texto_enviadoA{
      
      font-size: 15px;
      }
  
      .texto_comercio{
          
          font-size: 15px;
      }
      .texto_desc{
          
          font-size: 15px;
      }
      .text_ref{
          
          font-size: 15px;
      }
      .detalles_pago {
      margin-top: 7%;
      margin-left: -10%;
      }
  }
  
  @media (orientation: portrait) and (min-device-width: 821px) and (max-device-width: 912px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 60%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 20% 10% 20%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .botones_style {
      margin-top: 6%;
      /* margin-left: 3%; */
      text-align: center;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      /*z-index: 1031;*/
      padding: 1% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: 5%;
      margin-bottom: -4%;
      }
      .icono_share_pago {
      /* margin-top: -3%; */
      margin-left: 115%;
      color: #fff;
      cursor: pointer;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 30%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 15%;
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 25%;
      font-size: 15px;
      }
      .texto_enviadoA{
      
      font-size: 15px;
      }
  
      .texto_comercio{
          
          font-size: 15px;
      }
      .texto_desc{
          
          font-size: 15px;
      }
      .text_ref{
          
          font-size: 15px;
      }
      .detalles_pago {
      margin-top: 7%;
      margin-left: -10%;
      }
  }
  
  @media (orientation: portrait) and (min-device-width: 813px) and (max-device-width: 1280px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 60%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 20% 10% 20%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .botones_style {
      margin-top: 6%;
      /* margin-left: 3%; */
      text-align: center;
      }
      .modal-menu-catalogo {
      width: 100%;
      height: 100%;
      /*z-index: 1031;*/
      padding: 1% 20% 5% 8%;
      position: absolute;
      top: 0;
      left: 0;
      /* background: rgb(0, 0, 0); */
      /* opacity: .5; */
      }
      .style_share {
      margin-top: 5%;
      margin-bottom: -4%;
      }
      .icono_share_pago {
      /* margin-top: -3%; */
      margin-left: 115%;
      color: #fff;
      cursor: pointer;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 30%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 15%;
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 25%;
      font-size: 15px;
      }
      .texto_enviadoA{
      
      font-size: 15px;
      }
  
      .texto_comercio{
          
          font-size: 15px;
      }
      .texto_desc{
          
          font-size: 15px;
      }
      .text_ref{
          
          font-size: 15px;
      }
      .detalles_pago {
      margin-top: 7%;
      margin-left: -10%;
      }
  }
  /*horizontal */
  @media (orientation: landscape) and (min-device-width: 0px) and (max-device-width: 844px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 90%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 10% 5% 10% 5%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 30%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 20%;
      }
      .style_share {
      margin-top: -4%;
      }
      .style_cerrar {
      margin-top: -4%;
      }
  }
  
  @media (orientation: landscape) and (min-device-width: 845px) and (max-device-width: 913px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      /* width: 80%; */
      width: 90%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 5% 5% 10% 5%;
      position: absolute;
      top: 0;
      left: 0;
      }
      .style_precio {
      font-size: 40px;
      font-weight: bold;
      color: #37B8CB;
      text-align: center;
      margin-top: 5%;
      }
      .texto_descripcion {
      color: #37313F;
      /* font-weight: bold; */
      margin-left: 25%;
      font-size: 16px;
      }
      .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 30%;
      }
      .style_hora {
      color: #37B8CB;
      font-weight: bold;
      margin-right: 20%;
      }
      .style_share {
      margin-top: -4%;
      }
      .style_cerrar {
      margin-top: -4%;
      }
      .texto_exitoso {
      font-size: 34px;
      text-align: center;
      margin-top: 10%;
      }
      .texto_enviadoA{
      
      font-size: 16px;
      }
  
      .texto_comercio{
          
          font-size: 16px;
      }
      .texto_desc{
          
          font-size: 16px;
      }
      .text_ref{
          
          font-size: 16px;
      }
  }
  
  @media (orientation: landscape) and (min-device-width: 914px) and (max-device-width: 915px){
     .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 18%;
      }
      .detalles_pago {
      margin-top: 7%;
      margin-left: -20%;
      }
  }
  
  @media (orientation: landscape) and (min-device-width: 916px) and (max-device-width: 1180px){
     .style_fecha {
      color: #37B8CB;
      font-weight: bold;
      margin-left: 18%;
      }
      .detalles_pago {
      margin-top: 7%;
      margin-left: -15%;
      }
  }
  
  @media (min-width: 281px) and (max-width: 825px){
      .modal-container-40 {
      background: white;
      border-radius: 8px;
      width: 80%;
      height: auto;
      z-index: 1031;
      padding: 21px;
      margin: 13% 30% 5% 10%;
      position: absolute;
      }
  }
  