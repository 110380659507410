div {
  font-family: helvetica;
}
.div-left {
    margin-top: 1%;
    margin-left: 2%;
  }
  /* opción regresar */
  .regresar{
      color: #31373F;
      font-family : helvetica;
      font-size: 13px;
      font-weight: bold;
    margin-left: 3%;
    margin-bottom: 3%;
    cursor: pointer;
  }
  /* caja general */
  .ticket_view_flexcontainer {
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 4%;
    margin-bottom: 4%;
    padding-top: 5px;
      display: flex;
      flex-direction: row;
  }
  /* Estilos caja instrucciones */
  .ticket_view_caja{
      border: 1px solid #FFF;
      background-color: #FFF;
      color: #31373F;
      font-family: helvetica;
      font-weight: bold;
      width: 650px;
      height: 320px;
      /* line-height: 20px; */
      text-align: center;
      margin: 5px; 
      float: left;
    border-radius: 8px;
    margin-left: 20px;
    padding: 30px;
  }
  
    .title{
      color: #37B8CB;
      font-size: 30px;
      font-family : helvetica;
      text-align: left;
      padding-bottom: 8px;
    }
    .instrucciones {
      font-size: 15px;
      padding-left: 3%;
      padding-right: 3%;
      font-family : helvetica;
      text-align: left;
      font-weight: normal;
    }
  
  /*Estilos caja detalles*/
  .detalles{
      background-color: #37B8CB;
      width: 100%;
      /* height: 460px; */
    margin: 5px 10px 10px 10px;
      padding-left: 40px;
      padding-right: 40px;
    border-radius: 8px;
  }
    .leyenda{
      color: #37B8CB;
      font-size: 15px;
      font-weight: bold;
      font-family : helvetica;
      background: #fff;
      text-align:center;
      padding-top: 3px;
      padding-bottom: 3px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  
    .titulo_referencias {
      text-align: center;
      color: #fff;
      background: #37B8CB;
      font-size: 18px;
  }
  
    .referencias {
      color: #37B8CB;
      font-size: 25px;
      font-weight: bold;
      font-family : helvetica;
      background: #fff;
      text-align:center;
      margin: auto;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 8px;
    }
    .tiporeferencia{
      color: #37B8CB;
      font-size: 15px;
      font-weight: bold;
  
    }
  
    .img_ticket{
    width:200px;
    height: auto;
    }
    .flex_img_ticket{
      flex-grow: 1;
      margin-top: 0;
    }
    .flex_titulo_ticket {
      flex-grow: 4;
      padding-top: 3%;
      font-weight: bold;
      padding-left: 13px;
    }
    .contenedor_ticket {
    padding-left: 10px;
  }
  .contenedortj_ticket {
  padding-top:8px;
  }
  
  .botoncontainer_ticket {
      display: flex;
      margin-top: 4%;
      margin-bottom: 2%;
    }
  .monto{
    font-size: 15px;
    font-family : helvetica;
    color: #fff;
    text-align:center;
    font-weight: normal;
    padding-right: 15px;
  }
  .cantidad{
    font-size: 25px;
    font-weight: bold;
  }
  
  .pregunta{
      font-size: 17px;
      font-family : helvetica;
      font-weight: bold;
      color: #fff;
      position: relative;
      padding-top: 50px;
      text-align:center;
      justify-content: flex-end;
      display: end;
  }
  .info-pregunta{
      padding-top: 0px;	
    font-weight: normal;
  }
  
  .enlace-pregunta, .enlace-pregunta:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  
  .show-on-mobile_ticket  {
    display: none;
  }
  
  .show-on-web_ticket  {
    display: block;
  }
  
  /* RESPONSIVE */
  @media only screen and (min-device-width: 1368px) and (max-device-width: 1600px) and (orientation:landscape) {
    .regresar{
      margin-left: 2%;
      margin-bottom: 2%;
      font-size: 20px;
    }
  
    .instrucciones {
      font-size: 19px;
    }
  
    .ticket_view_caja {
      height: 420px;
    }
  }
  
  
  /* @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation:landscape) {
    .title {
      font-size: 45px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      font-size: 28px;
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      font-size: 34px;
    }
  
    .leyenda {
      font-size: 30px;
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:350px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 30px;
    }
  
    .cantidad {
      font-size: 50px;
    }
  
    .titulo_referencias {
      font-size: 30px;
    }
  
    .referencias {
      font-size: 50px;
    }
  
    .pregunta {
      padding-top: 8%;
      font-size: 30px;
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      font-size: 30px;
    }
  } */

  @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation:landscape) {
    .title {
      font-size: 34px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      font-size: 21px;
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      font-size: 34px;
    }
  
    .leyenda {
      font-size: 28px;
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:300px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 34px;
    }
  
    .cantidad {
      font-size: 55px;
    }
  
    .titulo_referencias {
      font-size: 34px;
    }
  
    .referencias {
      font-size: 34px;
    }
  
    .pregunta {
      padding-top: 8%;
      font-size: 34px;
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      font-size: 34px;
    }

    .ticket_view_flexcontainer {
      margin-left: 8%;
      margin-right: 8%;
    }
  }

  @media only screen and (min-device-width: 720px) and (max-device-width:915px) and (orientation:landscape) {
  
    .img_ticket{
      width:200px;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 21px;
    }
  
    .cantidad {
      font-size: 34px;
    }
  }

  @media only screen and (min-device-width: 916px) and (max-device-width:1024px) and (orientation:landscape) {
  
    .img_ticket{
      width:200px;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 21px;
    }
  
    .cantidad {
      font-size: 45px;
    }
  }

  @media only screen and (min-device-width: 653px) and (max-device-width: 719px) and (orientation:landscape) {
    .title {
      font-size: 34px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      font-size: 21px;
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      font-size: 34px;
    }
  
    .leyenda {
      font-size: 28px;
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:180px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 14px;
    }
  
    .cantidad {
      font-size: 26px;
    }
  
    .titulo_referencias {
      font-size: 34px;
    }
  
    .referencias {
      font-size: 34px;
    }
  
    .pregunta {
      padding-top: 8%;
      font-size: 34px;
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      font-size: 34px;
    }

    .ticket_view_flexcontainer {
      margin-left: 8%;
      margin-right: 8%;
    }
  }
  
  /* @media only screen and (min-device-width: 653px) and (max-device-width: 719px) and (orientation:landscape) {
    .title {
      font-size: 34px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      font-size: 21px;
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      font-size: 34px;
    }
  
    .leyenda {
      font-size: 28px;
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:180px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 14px;
    }
  
    .cantidad {
      font-size: 30px;
    }
  
    .titulo_referencias {
      font-size: 34px;
    }
  
    .referencias {
      font-size: 34px;
    }
  
    .pregunta {
      padding-top: 8%;
      font-size: 34px;
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      font-size: 34px;
    }
  } */

  @media only screen and (min-device-width: 768px) and (max-device-width: 912px) and (orientation:portrait) {
    .title {
      font-size: 40px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      font-size: 34px;
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      font-size: 34px;
    }
  
    .leyenda {
      font-size: 24px;
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:280px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 28px;
    }
  
    .cantidad {
      font-size: 30px;
    }
  
    .titulo_referencias {
      font-size: 34px;
    }
  
    .referencias {
      font-size: 34px;
    }
  
    .pregunta {
      padding-top: 8%;
      font-size: 34px;
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      font-size: 34px;
    }

    .ticket_view_flexcontainer {
      margin-left: 8%;
      margin-right: 8%;
    }
  
  }
  
  @media only screen and (min-device-width: 376px) and (max-device-width: 767px) and (orientation:portrait) {
    .title {
      font-size: 20px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      /* font-size: 28px; */
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      /* font-size: 34px; */
    }
  
    .leyenda {
      /* font-size: 25px; */
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:120px;
    }
    
    .botoncontainer_ticket {
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: right;
      margin-right: 13%;
      font-size: 15px;
    }
  
    .cantidad {
      font-size: 15px;
    }
  
    .titulo_referencias {
      /* font-size: 34px; */
    }
  
    .referencias {
      font-size: 20px;
    }
  
    .pregunta {
      padding-top: 8%;
      /* font-size: 34px; */
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      /* font-size: 34px; */
    }
    .detalles {
      background-color: #37B8CB;
      /* width: 200%; */
      /* height: 460px; */
      margin: 5px 10px 10px 10px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 8px;
    }
    .ticket_view_flexcontainer {
      margin-left: 8%;
      margin-right: 8%;
      margin-top: 8%;
      padding-top: 5px;
      display: flex;
      flex-direction: column-reverse;
    }
    .ticket_view_caja{
      width: 95%;
      height: auto;
    }
    .show-on-mobile_ticket {
      display: block;
    }
    .show-on-web_ticket {
      display: none;
    }

    .div-left {
      margin-left: -1%;
    }
  
  }
  @media only screen and (min-device-width: 0px) and (max-device-width: 375px) and (orientation:portrait) {
    .title {
      font-size: 20px;
      margin-bottom: 3%;
    }
  
    .instrucciones {
      /* font-size: 28px; */
      margin-bottom: 3%;
    }
  
    .regresar{
      margin-left: 1%;
      margin-bottom: 2%;
      /* font-size: 34px; */
    }
  
    .leyenda {
      /* font-size: 25px; */
      padding-top: 5px;
      padding-bottom: 5px; 
    }
  
    .img_ticket{
      width:140px;
    }
    
    .botoncontainer_ticket {
      display: block;
      margin-top: 2%;
      margin-bottom: 5%;
      text-align: center;
    }
  
    .monto {
      text-align: center;
      /* margin-right: 13%; */
      font-size: 12px;
    }
  
    .cantidad {
      font-size: 16px;
    }
  
    .titulo_referencias {
      font-size: 15px;
    }
  
    .referencias {
      font-size: 14px;
    }
  
    .pregunta {
      padding-top: 8%;
      /* font-size: 34px; */
    }
  
    .info-pregunta{
      padding-top: 0px;	
      padding-bottom: 4%;
      /* font-size: 34px; */
    }
    .detalles {
      background-color: #37B8CB;
      /* width: 300%; */
      /* height: 460px; */
      margin: 5px 10px 10px 10px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 8px;
    }
    .ticket_view_caja{
      height: auto;
      width: 95%;
    }
    .ticket_view_flexcontainer {
      margin-left: 8%;
      margin-right: 8%;
      margin-top: 8%;
      padding-top: 5px;
      display: flex;
      flex-direction: column-reverse;
    }
    .show-on-mobile_ticket {
      display: block;
    }
    .show-on-web_ticket {
      display: none;
    }

    .div-left {
      margin-left: -4%;
    }
  
  }
  
  
  @media (min-width:1281px) and (max-device-width: 1367px) {
    .show-on-mobile_ticket  {
      display: none;
    }
  
    .show-on-web_ticket  {
      display: block;
    }
  
    .ticket_view_caja{
      width: 100%;
      height: auto;
      margin: 0px; 
    }
  }
  
  @media (min-width: 500px) and (max-width: 1280px)  {
    .ticket_view_flexcontainer {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 4%;
    }
  
    .detalles{
      height: 25%;
    }
  
    .ticket_view_caja{
      width: 100%;
      height: auto;
      margin: 0px; 
    }
  
    .div-left {
      margin-bottom: 3%;
      margin-top: 1%;
      margin-left: 1%;
    }
  
    .flex_img_ticket{
      margin-top: 3%;
    }
  
    .show-on-mobile_ticket  {
      display: block;
    }
  
    .show-on-web_ticket  {
      display: none;
    }
  }
  
  
  