.logo-center {
    position: absolute;
    top: 35%;
    right: 0;
    left: 0;
    animation: heartbeat 2s infinite;
}

@keyframes heartbeat
{
    0% {
        transform: scale(.9)
      }
      25% {
        transform: scale(1)
      }
      50% {
        transform: scale(.9)
      }
      75% {
        transform: scale(1)
      }
      100% {
        transform: scale(.9)
      }
}