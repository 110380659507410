.bg-section {
  background: white;margin-top: 8px;border-radius: 8px;
}

#main {
  display: flex;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 3%;
  width: 75%;
}
#main > article {
  order: 1;
  padding: 30px 30px 30px 30px;
  border: #fff 4px;
  padding-left: 20px;
}

#main > aside {
  display: flex;
  margin-left: 5%;
  margin-top: 1%;
  flex-direction: column;
  order: 2;
}

article, aside {
  padding: 1em;
}
p{
  font-family: helvetica;
  color: #31373F;
}
/*tamaños de los textos, titulos y subtirulos*/
.titulo34 {  
  font-size: 40px;
}
.titulo21 {
  font-size: 25px;
  /* width: 65%; */
}
.titulo20 {
  font-size: 23px;
}
/*titulo detalle de compra*/
.titulodetalle, .titulodetalle:hover{
  font-size: 21px;
  text-align: center;
  color: #37B8CB;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  padding-right: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}
/*logo de detalles lado derecho*/
.logo {
  width: 250px;
  height: auto;
  display: block;
  margin: auto;
}

/*logos de la forma de pago */
.img_pago{
width:auto;
height: 33px;
text-align: right;
margin-top: 8%;
}
.flex_img{
text-align: right;
vertical-align: middle;
padding-right: 13px;
flex-grow: 1;
}

.flex_icon{
  text-align: right;
  vertical-align: middle;
  font-size: 21px;
  padding-right: 13px;
  flex-grow: 1;
  margin-top: 2%;
  color: #37B8CB;
}
/* texto de los botones de forma de pago */

.flex_titulo {
  flex-grow: 4;
  text-align: left;
  font-weight: bold;
  padding-left: 13px;
  padding-top: 15px;
}

.tituloforma{
  font-family: helvetica;
  font-size: 21px;
  width:auto;
  height: 33px;
  padding-right: 5px;
  text-align: left;
  font-weight: normal;
}

.tituloforma3 {
  padding-right: 15px;
}


/* icono de informacion */

.flexContainer {
display:block;
align-items:center;
text-align: center;
}

.customSpan {
margin:0 0 4px 4px;  
}
.customIcon {
color:#37B8CB;
font-size:17px;
padding: 15px;
}

/*botones del div forma de pago*/
#btn_forma_pago{
height: 60px;
width: 450px;
background-color: #fff;
border-left-style: solid;
border-radius: 3px;
cursor: pointer;
}
.botonespy {
border: #0037AD 5px;
}

.botonesox {
border: #79C300 5px;
}
.botonestj {
border: #FFB600 5px;
}
.botonescash {
border: #009EDB 5px
}

.botonesspei {
border: #685BC7 5px
}

.botonesbank {
border: #F4364C 5px;
}

.botoncontainer {
  display: flex;
  flex-grow: 5;
}
/* clase de cada div de los botones de forma de pago*/ 
.contenedor {
display: flex;
padding-left: 13px;
padding-bottom:13px;
}
/*saludo y seleccionar forma de pago*/
.titulopago {
padding-top: 8px;
padding-left: 0px;
padding-bottom: 8px;
}
.textosaludo{
padding-left: 55px;
}

/*poner una clase */
.tabla_pago { 
 width: 100%;
 text-align: center;
 font-size: 16px;
}
.td_th_tabla{
 padding-left: 6%;
 padding-right: 6%;
 vertical-align: middle;
}

.td_title {
text-align: right;
font-size: 21px;
font-weight: normal;
}

.td_subtitle {
text-align: left;
font-size: 18px;
font-weight: normal;
}

.hr_style {
margin-top: 10px;
}

/*boton continuar habilitado y deshabilitado*/
.btn_continuar {
background: #37B8CB;
color: #fff;
border: none;
width: 90%;
margin-top: 8%;
margin-left: 5%;
border-radius: 3px;
height: 40px;
margin-bottom: 20px;
}
.btn_continuar_disable {
background: #31373F;
opacity: 0.3;
cursor: not-allowed;
color: #fff;
border: none;
width: 90%;
margin-top: 8%;
margin-left: 5%;
border-radius: 3px;
height: 40px;
margin-bottom: 20px;
}

/*padding del boton*/
.botonespacio{
align-self: flex-end;
}

.show-on-mobile {
display: none;
}

.show-on-web {
display: block;
}

.text_subtitle {
font-weight: normal;
}

/* RESPONSIVE */
@media only screen and (min-device-width: 1368px) and (max-device-width: 1600px) and (orientation:landscape) {
.flex_icon{
  margin-top: 3%;
  font-size: 25px;
}
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation:landscape) {
 /* SALUDO NOMBRE */
.titulo34 {
margin-right: 30%;
margin-top: 2%;
}

/* INFO PAGO */
.titulo21 {
font-size: 28px;
margin-top: 2%;
margin-bottom: 2%;
}

/* PREGUNTA FORMA DE PAGO */
.titulo20 {
font-size: 35px;
margin-bottom: 2%;
} 

/* BOTÓN FORMA DE PAGO */

#btn_forma_pago {
height: 70px;
}
.tituloforma {
font-size: 28px;
}

.img_pago {
margin-top: 10%;
}

.flex_icon{
margin-top: 2%;
font-size: 33px;
}

/* DETALLE */
.text_titulodetalle, .text_titulodetalle:hover {
font-size: 33px;
padding-top: 20px;
padding-bottom: 20px;
cursor: pointer;
}

.text_titulodetalle span {
font-size: 20px;
}

.text_title {
font-size: 35px;
}
.text_subtitle {
font-size: 35px;
}

.text_btn_continuar {
font-size: 28px;
}
}

@media only screen and (min-device-width: 653PX) and (max-device-width: 719px) and (orientation:landscape) {
/* SALUDO NOMBRE */
.titulo34 {
  margin-right: 30%;
  margin-top: 2%;
}

/* INFO PAGO */
.titulo21 {
  font-size: 33px;
  margin-top: 2%;
  margin-bottom: 2%;
}

/* PREGUNTA FORMA DE PAGO */
.titulo20 {
  font-size: 40px;
  margin-bottom: 2%;
} 

/* BOTÓN FORMA DE PAGO */

#btn_forma_pago {
  height: 75px;
}
.tituloforma {
  font-size: 33px;
  padding-right: 10px;
}

.img_pago {
  margin-top: 10%;
}

.flex_icon{
  margin-top: 2%;
  font-size: 33px;
}

/* DETALLE */
.text_titulodetalle, .text_titulodetalle:hover {
  font-size: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.text_titulodetalle span {
  font-size: 25px;
}

.text_title {
  font-size: 40px;
}
.text_subtitle {
  font-size: 40px;
}

.text_btn_continuar {
  font-size: 30px;
}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 912px) and (orientation:portrait) {
  /* SALUDO NOMBRE */
.titulo34 {
  font-size: 40px;
  margin-right: 43%;
}

/* INFO PAGO */
.titulo21 {
  font-size: 40px;
  margin-top: 2%;
  margin-bottom: 2%;
}

/* PREGUNTA FORMA DE PAGO */
.titulo20 {
  font-size: 50px;
  margin-bottom: 2%;
}

/* BOTÓN FORMA DE PAGO */

#btn_forma_pago {
  height: 75px;
}
.tituloforma {
  font-size: 25px;
}

.img_pago {
  margin-top: 10%;
}

.flex_icon{
  margin-top: 2%;
  font-size: 33px;
}

/* DETALLE */
.text_titulodetalle, .text_titulodetalle:hover {
  font-size: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.text_titulodetalle span {
  font-size: 35px;
}

.text_title {
  font-size: 45px;
}
.text_subtitle {
  font-size: 45px;
}

.text_btn_continuar {
  font-size: 30px;
}

}

@media only screen and (min-device-width: 280px) and (max-device-width: 480px) and (orientation:portrait) {
  #main {
    display: block;
    margin-left: 0%;
    width: 90%;
  }

  .logo {
    width: 150px;
    height: auto;
    position: absolute;
    margin-right: 5%;
    margin-top: 5%;
    right: 0;
    top: 0;
  }

  .titulodetalle {
    width: auto;
  }

  .customIcon {
    padding: 0px 0px 0px 5px;
  }

  .textosaludo {
    padding-left: 10px;
  }

  .show-on-mobile {
    display: block;
  }
  
  .show-on-web {
    display: none;
  }

  /* SALUDO NOMBRE */
.titulo34 {
  font-size: 30px;
  margin-right:40%;
}

/* INFO PAGO */
.titulo21 {
  font-size: 24px;
  margin-top: 2%;
  margin-bottom: 2%;
}

/* PREGUNTA FORMA DE PAGO */
.titulo20 {
  font-size: 18px;
  margin-bottom: 2%;
}

/* BOTÓN FORMA DE PAGO */

#btn_forma_pago {
  height: 70px;
}
.tituloforma {
  font-size: 17px;
  margin-top: 4%;
  vertical-align: middle;
}

.img_pago {
  margin-top: 55%;
  width: auto;
  height: 21px;
}

.flex_icon{
  margin-top: 10%;
  font-size: 18px;
  margin-left: 4%;
}

/* DETALLE */
.text_titulodetalle, .text_titulodetalle:hover {
  font-size: 21px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.text_titulodetalle span {
  font-size: 15px;
}

.text_title {
  font-size: 21px;
}
.text_subtitle {
  font-size: 21px;
}

.text_btn_continuar {
  font-size: 21px;
}

}

@media only screen and (min-device-width: 280px) and (max-device-width: 406px) and (orientation:portrait) {
  .tituloforma2 {
    margin-top: -2%;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 427px) and (orientation:portrait) {
  .tituloforma3 {
    margin-top: -2%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1367px)  {
#main {
  display: block;
  margin-left: 2%;
  width: 90%;
}

.botoncontainer {
  flex-grow: 13;
}

.textosaludo{
  padding-left: 55px;
}

.logo {
  width: 250px;
  height: auto;
  position: absolute;
  margin-right: 8%;
  margin-top: 5%;
  right: 0;
  top: 0;
}

.btn_continuar {
  height: 60px;
  font-size: 21px;
  margin-bottom: 4%;
}

.btn_continuar_disable {
  height: 60px;
  font-size: 21px;
  margin-bottom: 4%;
}

aside {
  padding-left: 5%;
  margin-bottom: 3%;
}

.titulo21 {
  margin-right: 30%;
}

.show-on-mobile {
  display: block;
}

.show-on-web {
  display: none;
}
}

@media only screen and (min-width: 0px) and (max-width: 623px) {

  .textosaludo {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 499px) {
  .logo {
    width: 180px;
    height: auto;
    position: absolute;
    margin-right: 8%;
    margin-top: 5%;
    right: 0;
    top: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 390px) {
  .titulo34{
    margin-right: 0%;
    margin-top: 25%;
  }
}